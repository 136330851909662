import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';
import { updateAuthUser } from './useAuth';

export function useUpdateProfile() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.profile.update(data),
        onSuccess: (newUser) => {
            updateAuthUser(queryClient, newUser);
        },
    });

    return {
        updateProfile: mutate,
        updateProfileAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useUpdateContact() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.profile.updateContact(data),
        onSuccess: (newUser) => {
            updateAuthUser(queryClient, newUser);
        },
    });

    return {
        updateContact: mutate,
        updateContactAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useVerifyContact() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: ({ contact, ...data }) => api.profile.verifyContact(contact, data),
        onSuccess: (newUser) => {
            updateAuthUser(queryClient, newUser);
        },
    });

    return {
        verifyContact: mutate,
        verifyContactAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useCancelContact() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.profile.cancelContact(data),
        onSuccess: (newUser) => {
            updateAuthUser(queryClient, newUser);
        },
    });

    return {
        cancelContact: mutate,
        cancelContactAsync: mutateAsync,
        ...mutationResult,
    };
}
