/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearch } from 'wouter';

import { useSubscription } from '../../hooks/useSubscription';

import { useBrand, useDefaultBrand, useSite } from '../../contexts/SiteContext';
import UrbaniaButton from '../buttons/UrbaniaButton';
import SubscriptionForm from '../forms/SubscriptionForm';
import PromotionDescription from '../typography/PromotionDescription';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/pages/subscription-page.module.css';
import { useIsCheckingAuth } from '../../contexts/AuthContext';

const propTypes = {
    brandSlug: PropTypes.string,
    disabled: PropTypes.bool,
    current: PropTypes.bool,
    subscribe: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    brandSlug: null,
    disabled: false,
    current: false,
    subscribe: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
};
function SubscriptionPage({
    brandSlug,
    disabled,
    subscribe: shouldSubscribe,
    current,
    entered,
    entering,
    leaving,
    className,
    contentClassName,
}) {
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null, label: brandLabel } = brand || {};
    const search = useSearch();
    const { source: sourceQuery = null } = useMemo(() => queryString.parse(search) || {}, [search]);
    const { subscriptions } = useSite();
    const subscription = subscriptions.find(
        ({ handle: subscriptionHandle }) => subscriptionHandle === brandHandle,
    );
    const isCheckingAuth = useIsCheckingAuth();
    const user = useUser();
    const { description } = subscription;
    const { subscribed, subscribe } = useSubscription({
        subscription: brandHandle,
        source: sourceQuery || 'link',
    });

    useEffect(() => {
        if (shouldSubscribe && current) {
            subscribe();
        }
    }, [shouldSubscribe]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
            data-brand={brandHandle}
        >
            <UrbaniaButton brand={brandHandle} className={styles.urbania} />
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <PromotionTitle className={styles.title}>
                        {subscribed ? (
                            <FormattedMessage
                                defaultMessage="Vous êtes abonné·e à {brand}!"
                                values={{
                                    brand: brandLabel,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Abonnez-vous à {brand}!"
                                values={{
                                    brand: brandLabel,
                                }}
                            />
                        )}
                    </PromotionTitle>
                    <PromotionDescription className={styles.description} html={description} />
                    <div className={styles.form}>
                        {!isCheckingAuth || user !== null ? (
                            <SubscriptionForm
                                subscription={brandHandle}
                                full={subscribed}
                                source="page"
                                ssoSize="large"
                                withoutButtons
                                fieldsClassName={styles.fields}
                                subscriptionsClassName={styles.subscriptions}
                                ssoClassName={styles.sso}
                            />
                        ) : (
                            <div className={styles.placeholder}>Chargement...</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

SubscriptionPage.propTypes = propTypes;
SubscriptionPage.defaultProps = defaultProps;

export default SubscriptionPage;
