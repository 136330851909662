/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useSubscribed } from '../../hooks/useSubscription';

import { useMatchedPromotions, usePromotions } from '../../contexts/PromotionsContext';
import PromotionSubscriptionCard from '../cards/PromotionSubscriptionCard';

import styles from '../../styles/blocks/subscription-block.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function SubscriptionBlock({ className, ...props }) {
    const matchedPromotions = useMatchedPromotions();
    const [currentPromotion = null] = matchedPromotions.filter(
        ({ type, placement }) => type === 'subscription' && placement === 'block',
    );
    const subscribed = useSubscribed(
        currentPromotion !== null ? currentPromotion.subscription : null,
    );
    if (subscribed || currentPromotion === null) {
        return null;
    }
    return (
        <div className={classNames([styles.container, className])}>
            <PromotionSubscriptionCard
                {...currentPromotion}
                {...props}
                className={styles.card}
                innerClassName={styles.inner}
            />
        </div>
    );
}

SubscriptionBlock.propTypes = propTypes;
SubscriptionBlock.defaultProps = defaultProps;

export default SubscriptionBlock;
