/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscription } from '../../hooks/useSubscription';
import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';
import { getTrackingQuery } from '../../lib/utils';

import { useSubscriptions } from '../../contexts/SiteContext';
import SubscriptionForm from '../forms/SubscriptionForm';
import Summary from './Summary';

import styles from '../../styles/summaries/author-summary.module.css';

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    clickRef: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    withButton: PropTypes.bool,
    withSubscribeButton: PropTypes.bool,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
};

const defaultProps = {
    id: null,
    slug: null,
    clickRef: null,
    name: null,
    title: null,
    description: null,
    shortDescription: null,
    withButton: false,
    withSubscribeButton: false,
    className: null,
    imageClassName: null,
    pictureClassName: null,
};

function AuthorSummary({
    id,
    slug,
    clickRef,
    name,
    title,
    description,
    shortDescription,
    withButton,
    withSubscribeButton,
    className,
    imageClassName,
    pictureClassName,
    ...props
}) {
    const url = useAuthorUrlGenerator();
    const subscriptions = useSubscriptions();
    const subscription =
        subscriptions.find(({ author = null }) => author !== null && author.id === id) || null;
    const { subscribed = false, unsubscribe } = useSubscription({
        subscription: subscription !== null ? subscription.handle : null,
    });
    return (
        <Summary
            link={url(
                {
                    slug,
                },
                getTrackingQuery({
                    clickRef: clickRef || 'summary',
                }),
            )}
            title={name}
            description={shortDescription || description}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            imageClassName={classNames([
                styles.image,
                {
                    [imageClassName]: imageClassName !== null,
                },
            ])}
            pictureClassName={classNames([
                styles.picture,
                {
                    [pictureClassName]: pictureClassName !== null,
                },
            ])}
            buttonLabel={<FormattedMessage defaultMessage="Voir plus" description="Button label" />}
            withButton={withButton && !withSubscribeButton}
            {...props}
        >
            {subscription !== null && subscribed !== null && !subscribed && withSubscribeButton ? (
                <div className={styles.subscription}>
                    <SubscriptionForm
                        subscription={subscription.handle}
                        buttonOnly
                        compact
                        withoutSso
                        className={styles.form}
                    />
                </div>
            ) : null}
        </Summary>
    );
}

AuthorSummary.propTypes = propTypes;
AuthorSummary.defaultProps = defaultProps;

export default AuthorSummary;
