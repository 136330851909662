/* eslint-disable func-names */
import createDebug from 'debug';
import { th } from 'intl-tel-input/i18n';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import set from 'lodash/set';
import EventEmitter from 'wolfy87-eventemitter';

const debug = createDebug('app:tracking:triggers');

class TriggersManager extends EventEmitter {
    constructor(opts) {
        super();

        const { dataLayer, ...otherOpts } = opts;
        this.options = otherOpts;

        this.dataLayer = dataLayer;
        this.triggers = [];
        this.triggersCalled = [];
        this.garbageState = {};
        this.triggersState = {};

        this.state = {};

        this.documentsViewsCount = 0;
    }

    register(id, trigger, callback) {
        const { triggers = null, ...triggerObject } = isObject(trigger)
            ? trigger
            : TriggersManager.parseTrigger(trigger);
        const { persist = false } = triggerObject;
        const currentTriggerIndex = this.triggers.findIndex(
            ({ id: triggerId }) => triggerId === id,
        );
        const newTrigger = {
            id,
            trigger: {
                ...triggerObject,
                ...(triggers !== null
                    ? {
                          triggers: triggers.map((subTrigger) =>
                              isObject(subTrigger)
                                  ? subTrigger
                                  : TriggersManager.parseTrigger(subTrigger),
                          ),
                      }
                    : null),
            },
            callback,
        };
        debug('Register trigger "%s" %O', id, newTrigger);
        if (currentTriggerIndex !== -1) {
            this.triggers[currentTriggerIndex] = newTrigger;
        } else {
            this.triggers.push(newTrigger);
        }
        if (persist) {
            this.triggersState = {
                ...this.triggersState,
                id: {
                    ...(this.triggersState[id] || null),
                    ...TriggersManager.pullState(id),
                },
            };
        }

        this.handleTriggerEvent(newTrigger, {
            name: 'register',
            ...this.getEventState(this.state),
        });

        // Calling triggers
        this.callingTriggers();
    }

    unregister(id) {
        const currentTrigger = this.triggers.find(({ id: triggerId }) => triggerId === id) || null;
        if (currentTrigger === null) {
            return;
        }
        const { [id]: state = null } = this.triggersState || {};
        const { timeout = null } = state || {};
        debug('Unregister trigger "%s"', id, state);
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        this.triggers = this.triggers.filter(({ id: triggerId }) => triggerId !== id);
    }

    isRegistered(id) {
        return this.triggers.findIndex(({ id: triggerId }) => triggerId === id) !== -1;
    }

    getRegistered() {
        return this.triggers.map(({ id }) => id);
    }

    updateState(update) {
        const { event, eventId, ...newState } = update;
        this.state = {
            ...this.state,
            ...newState,
        };
        return this.state;
    }

    push(...args) {
        args.forEach((arg) => {
            if (!isObject(arg)) {
                return;
            }
            if (typeof arg.event === 'undefined') {
                this.updateState(arg);
                return;
            }
            let eventName = arg.event.toLowerCase();
            if (eventName === 'eventinteraction') {
                const { eventCategory, eventAction } = arg;
                eventName = `${eventCategory.toLowerCase()}_${eventAction.toLowerCase()}`;
            }
            this.handleEvent(eventName, this.getEventState(this.updateState(arg)));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getEventState(currentState) {
        const {
            user = null,
            identified = false,
            hasPage = false,
            consentGiven,
            pageType,
            brands,
            pageTitle: title,
            categories,
            tags,
            authors,
            identifiers,
            scrollPercent,
            documentProgress,
            screenIndex,
        } = currentState;
        const { subscriptions = [] } = user || {};
        const eventState = {
            page: hasPage
                ? {
                      title,
                      type: pageType,
                      brands,
                      categories,
                      tags,
                      authors,
                      identifiers,
                  }
                : null,
            identified,
            authenticated: user !== null,
            subscriptions: (subscriptions || [])
                .filter(({ subscribed = false }) => subscribed)
                .map(({ id }) => TriggersManager.sanitizeKeyword(id)),
            consentGiven: typeof consentGiven !== 'undefined' ? consentGiven : null,
            scrollPercent,
            documentProgress,
            screenIndex: pageType === 'micromag' ? screenIndex : null,
        };
        return eventState;
    }

    // eslint-disable-next-line class-methods-use-this
    getDataLayerState(dataLayer) {
        const user = dataLayer.get('user') || null;
        const { subscriptions = [] } = user || {};
        const consentGiven = dataLayer.get('consentGiven');
        const pageType = dataLayer.get('pageType');
        const dataLayerState = {
            page: {
                title: dataLayer.get('pageTitle'),
                type: pageType,
                brands: dataLayer.get('brands'),
                categories: dataLayer.get('categories'),
                tags: dataLayer.get('tags'),
                authors: dataLayer.get('authors'),
                identifiers: dataLayer.get('identifiers'),
            },
            hasPage: dataLayer.get('hasPage'),
            authenticated: user !== null,
            subscriptions: (subscriptions || [])
                .filter(({ subscribed = false }) => subscribed)
                .map(({ id }) => TriggersManager.sanitizeKeyword(id)),
            consentGiven: typeof consentGiven !== 'undefined' ? consentGiven : null,
            scrollPercent: dataLayer.get('scrollPercent'),
            documentProgress: dataLayer.get('documentProgress'),
            screenIndex: pageType === 'micromag' ? dataLayer.get('screenIndex') : null,
        };
        return dataLayerState;
    }

    handleEvent(event, dataLayerObject) {
        const eventObject = {
            name: event,
            ...dataLayerObject,
        };

        debug('Handle event "%s"', event, eventObject);

        // Compute triggers states
        this.triggers.forEach((triggerDef) => {
            this.handleTriggerEvent(triggerDef, eventObject);
        });

        // Calling triggers
        this.callingTriggers();
    }

    handleTriggerEvent(triggerDef, eventObject) {
        const { id: triggerId, trigger } = triggerDef;
        const { [triggerId]: currentState = null } = this.triggersState || {};
        const { matched: currentMatched = false } = currentState || {};

        if (!TriggersManager.matchTrigger(trigger, eventObject) || currentMatched === 'once') {
            return false;
        }
        const { persist = false } = trigger || {};

        debug('Match trigger "%s"', triggerId);
        const newState = TriggersManager.computeState(triggerDef, eventObject);

        if (currentState === newState) {
            return false;
        }

        debug('Update state for Trigger "%s" update state %o', triggerId, newState);

        if (persist) {
            debug('Trigger "%s" persist state', triggerId);
            TriggersManager.persistState(triggerId, newState);
        }

        this.triggersState = {
            ...this.triggersState,
            [triggerId]: newState,
        };
        return true;
    }

    callingTriggers() {
        this.triggers.forEach((trigger) => {
            const { id, trigger: { delay = null } = {} } = trigger;
            const { [id]: state = null } = this.triggersState || {};
            const { matched = false, called = false, timeout = null } = state || {};
            if (called || timeout !== null || matched === false) {
                return;
            }
            let newState;
            if (delay !== null) {
                debug('Calling trigger "%s" in %d ms...', id, delay);
                const newTimeout = setTimeout(() => {
                    this.callingTrigger(id);
                }, delay);
                newState = {
                    ...state,
                    timeout: newTimeout,
                };
            } else {
                newState = this.callingTrigger(id);
            }
            this.triggersState = {
                ...this.triggersState,
                [id]: newState,
            };
        });
    }

    callingTrigger(id) {
        const trigger = this.triggers.find(({ id: triggerId }) => triggerId === id) || null;
        if (trigger === null) {
            return null;
        }
        const { [id]: state = null } = this.triggersState || {};
        const { trigger: { persist = false } = {}, callback } = trigger;
        const { matched = false } = state || {};

        debug('Calling trigger "%s"...', id);

        callback();

        this.triggersCalled =
            this.triggersCalled.indexOf(id) === -1
                ? [...this.triggersCalled, id]
                : this.triggersCalled;

        const shouldReset = TriggersManager.triggerShouldReset(trigger);
        const newState = shouldReset
            ? {}
            : {
                  ...state,
                  called: matched === 'once',
              };
        if (shouldReset && persist) {
            TriggersManager.persistState(id, newState);
        }

        return {
            ...newState,
            timeout: null,
        };
    }

    static triggerShouldReset(trigger) {
        const { id, trigger: { reset = null } = {} } = trigger;
        const { [id]: state = null } = this.triggersState || {};
        const { matched = false } = state || {};
        return (reset === null && matched === true) || reset === true;
    }

    static matchTrigger(trigger, event) {
        return [
            // Match event name
            ({ event: triggerEvent = null }, { name: eventName }) =>
                triggerEvent === eventName ||
                ['sequence', 'all', 'one'].indexOf(triggerEvent) !== -1,

            // Match page
            ({ pageMatch = null }, { page }) =>
                pageMatch === null || TriggersManager.matchPage(page, pageMatch),

            // Match consent
            ({ consentGiven: triggerConsentGiven }, { consentGiven = null }) =>
                typeof triggerConsentGiven === 'undefined' || triggerConsentGiven === consentGiven,

            // Match has page
            ({ hasPage: triggerHasPage = null }, { page = null }) =>
                triggerHasPage === null ||
                (triggerHasPage && page !== null) ||
                (!triggerHasPage && page === null),

            // Match identified
            ({ identified: triggerIdentified = null }, { identified = false }) =>
                triggerIdentified === null || triggerIdentified === identified,

            // Match authenticated
            ({ authenticated: triggerAuthenticated = null }, { authenticated = false }) =>
                triggerAuthenticated === null || triggerAuthenticated === authenticated,

            // Match not subscribed
            ({ not_subscribed: triggerNotSubscribed = [] }, { subscriptions = [] }) => {
                const notSubscribed = TriggersManager.normalizeKeywords(triggerNotSubscribed);
                return (
                    notSubscribed.length === 0 ||
                    (subscriptions || []).reduce(
                        (acc, handle) => acc && notSubscribed.indexOf(handle) === -1,
                        true,
                    )
                );
            },

            // Match subscribed
            ({ subscribed: triggerSubscribed = [] }, { subscriptions = [] }) => {
                const subscribed = TriggersManager.normalizeKeywords(triggerSubscribed);
                return (
                    subscribed.length === 0 ||
                    (subscriptions || []).reduce(
                        (acc, handle) => acc || subscribed.indexOf(handle) !== -1,
                        true,
                    )
                );
            },

            // Match scroll percent
            ({ scrollPercent: triggerScrollPercent = null }, { scrollPercent = null }) =>
                triggerScrollPercent === null ||
                (scrollPercent !== null && scrollPercent >= triggerScrollPercent),

            // Match screen index
            (
                {
                    minScreenIndex: triggerMinScreenIndex = null,
                    maxScreenIndex: triggerMaxScreenIndex = null,
                    screenIndex: triggerScreenIndex = null,
                },
                { screenIndex = null },
            ) =>
                (triggerMinScreenIndex === null ||
                    (screenIndex !== null && screenIndex >= triggerMinScreenIndex)) &&
                (triggerMaxScreenIndex === null ||
                    (screenIndex !== null && screenIndex <= triggerMaxScreenIndex)) &&
                (triggerScreenIndex === null ||
                    (screenIndex !== null && screenIndex === triggerScreenIndex)),

            // Match document progress
            ({ documentProgress: triggerDocumentProgress = null }, { documentProgress = null }) =>
                triggerDocumentProgress === null ||
                (documentProgress !== null && documentProgress >= triggerDocumentProgress),

            // Match triggers
            ({ triggers = null }, subEvent) =>
                triggers === null ||
                triggers.reduce(
                    (match, subTrigger) =>
                        TriggersManager.matchTrigger(subTrigger, subEvent) || match,
                    false,
                ),
        ].reduce((allMatch, matcher) => allMatch && matcher(trigger, event), true);
    }

    static matchPage(page, pageMatch) {
        const {
            brands = null,
            brand = null,
            keywords = null,
            types = null,
            type = null,
            notType = null,
        } = pageMatch || {};
        const finalBrands = [
            ...TriggersManager.normalizeKeywords(brands),
            ...TriggersManager.normalizeKeywords(brand),
        ];
        const finalKeywords = TriggersManager.normalizeKeywords(keywords);
        const finalTypes = [
            ...TriggersManager.normalizeKeywords(types),
            ...TriggersManager.normalizeKeywords(type),
        ];
        const finalNotTypes = [...TriggersManager.normalizeKeywords(notType)];
        const {
            type: pageType,
            brands: pageBrands = null,
            categories: pageCategories = null,
            keywords: pageKeywords = null,
            authors: pageAuthors = null,
            tags: pageTags = null,
        } = page || {};

        const allKeywords = TriggersManager.normalizeKeywords([
            ...(pageBrands || []),
            ...(pageCategories || []),
            ...(pageKeywords || []),
            ...(pageAuthors || []),
            ...(pageTags || []),
        ]);
        const matchBrands =
            finalBrands === null ||
            finalBrands.length === 0 ||
            (pageBrands || []).findIndex((it) => finalBrands.indexOf(it) !== -1) !== -1;
        const matchKeywords =
            finalKeywords === null ||
            finalKeywords.length === 0 ||
            (allKeywords || []).findIndex((it) => finalKeywords.indexOf(it) !== -1) !== -1;
        const matchType =
            (finalTypes === null ||
                finalTypes.length === 0 ||
                finalTypes.indexOf(pageType) !== -1) &&
            (finalNotTypes === null ||
                finalNotTypes.length === 0 ||
                finalNotTypes.indexOf(pageType) === -1);
        return matchBrands && matchKeywords && matchType;
    }

    static normalizeKeywords(keywords) {
        return ((keywords !== null && isString(keywords) ? [keywords] : keywords) || [])
            .map((it) => (isString(it) ? TriggersManager.sanitizeKeyword(it) : null))
            .filter((it) => !isEmpty(it));
    }

    static sanitizeKeyword(keyword) {
        return isString(keyword) ? (keyword || '').toLowerCase().replace(/[^a-z0-9]+/gi, '') : null;
    }

    static computeState(triggerDef, event) {
        const { id, trigger } = triggerDef;
        const { [id]: state = null } = this.triggersState || {};
        const { event: triggerEvent, triggers = null, once = true } = trigger || {};

        if (triggers !== null) {
            const { states: currentTriggerStates = [], matched: currentMatched = false } =
                state || {};
            const { states: newTriggerStates } = triggers.reduce(
                ({ states: triggerStates, previousMatched = false }, subTrigger, index) => {
                    // Skip this sub trigger
                    if (
                        (triggerEvent === 'sequence' && previousMatched === false) ||
                        !TriggersManager.matchTrigger(subTrigger, event)
                    ) {
                        const { matched = false } = triggerStates[index] || {};
                        return {
                            states:
                                triggerStates.length <= index
                                    ? [...triggerStates, { matched: false }]
                                    : triggerStates,
                            previousMatched:
                                previousMatched === null || previousMatched !== false
                                    ? matched
                                    : previousMatched,
                        };
                    }
                    const subTriggerState = triggerStates[index] || null;
                    const newState = TriggersManager.computeTriggerState(
                        subTrigger,
                        subTriggerState,
                    );
                    return {
                        states:
                            newState !== subTriggerState
                                ? [...triggerStates.slice(0, index), newState]
                                : triggerStates,
                        previousMatched: newState.matched !== false,
                    };
                },
                {
                    states: currentTriggerStates,
                    previousMatched: null,
                },
            );
            const allMatched = newTriggerStates.reduce((allMatch, { matched = false }) => {
                if (allMatch === null) {
                    return matched !== false;
                }
                return (
                    (allMatch && matched !== false) ||
                    (triggerEvent === 'one' && (allMatch || matched !== false))
                );
            }, null);
            const newMatched = allMatched && once ? 'once' : allMatched;
            const stateChanged =
                newMatched !== currentMatched || newTriggerStates !== currentTriggerStates;
            return stateChanged
                ? {
                      ...state,
                      states: newTriggerStates,
                      matched: newMatched,
                  }
                : state;
        }

        return TriggersManager.computeTriggerState(trigger, state);
    }

    static pullState(id) {
        const state = Cookies.get(`trigger_state_${id}`) || null;
        return state !== null ? TriggersManager.parseObject(state) : null;
    }

    static persistState(id, state) {
        const serializedState = TriggersManager.serializeObject(state);
        Cookies.set(`trigger_state_${id}`, serializedState, {
            expires: 30,
        });
    }

    static computeTriggerState(trigger, state) {
        const { count: triggerCount = 1, once = true } = trigger || {};
        const { matched: currentMatched = false, count: currentCount = 0 } = state || {};
        const newCount = currentCount + 1;
        const countMatch = newCount >= triggerCount;
        const triggerMatched = countMatch;
        const newMatched = triggerMatched && once ? 'once' : countMatch;
        const stateChanged = newMatched !== currentMatched || newCount !== currentCount;
        return stateChanged
            ? {
                  ...state,
                  matched: newMatched,
                  count: newCount,
              }
            : state;
    }

    static serializeObject(trigger, prefix = null) {
        let triggerObject = trigger;
        if (isString(trigger)) {
            triggerObject = TriggersManager.parseObject(trigger);
        }
        const objectMap = isArray(triggerObject)
            ? triggerObject.map((value, key) => [key, value])
            : Object.keys(triggerObject).map((key) => [key, triggerObject[key]]);
        return objectMap
            .map(([key, value]) => {
                const keyWithPrefix = prefix !== null ? `${prefix}.${key}` : key;
                if (isObject(value)) {
                    return TriggersManager.serializeObject(value, keyWithPrefix);
                }
                if (isArray(value) && value.length > 0 && isObject(value[0])) {
                    return TriggersManager.serializeObject(value, keyWithPrefix);
                }
                return value === true
                    ? keyWithPrefix
                    : `${keyWithPrefix}:${isArray(value) ? value.join(',') : value}`;
            })
            .join('|');
    }

    static parseTrigger(trigger) {
        const [event, parameters = null] = trigger.split('|', 2);
        return {
            event,
            ...TriggersManager.parseObject(parameters),
        };
    }

    static parseObject(trigger) {
        return (trigger || '').split('|').reduce((acc, part) => {
            if (part.length === 0) {
                return acc;
            }
            const [key, value = null] = part.split(':', 2);
            let finalValue = value !== null && value.indexOf(',') !== -1 ? value.split(',') : value;
            if (finalValue === 'false') {
                finalValue = false;
            } else if (finalValue === 'true') {
                finalValue = true;
            } else if (finalValue === 'null') {
                finalValue = null;
            } else if (finalValue !== null && finalValue.match(/^[0-9]+$/) !== null) {
                finalValue = parseInt(finalValue, 10);
            } else if (finalValue !== null && finalValue.match(/^[0-9.]+$/) !== null) {
                finalValue = parseFloat(finalValue);
            }
            set(acc, key, finalValue !== null ? finalValue : true);
            return acc;
        }, {});
    }
}

export default TriggersManager;
