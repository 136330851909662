/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-danger */
import { useVideoPlayer, useIsVisible } from '@folklore/hooks';
import { useVideoTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import { useRequestBase } from '../../contexts/RequestContext';
import Controls from './Controls';

import styles from '../../styles/partials/video.module.css';

const propTypes = {
    media: AppPropTypes.video.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    autoPlay: PropTypes.bool,
    playing: PropTypes.bool,
    muted: PropTypes.bool,
    loading: PropTypes.oneOf([null, 'lazy']),
    withoutSize: PropTypes.bool,
    cover: PropTypes.bool,
    controls: PropTypes.bool,
    controlsCompact: PropTypes.bool,
    controlsOver: PropTypes.bool,
    controlsTheme: PropTypes.string,
    nativeControls: PropTypes.bool,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,
    onMute: PropTypes.func,
    onUnmute: PropTypes.func,
    onProgress: PropTypes.func,
    onClick: PropTypes.func,
    onClickInfo: PropTypes.func,
    className: PropTypes.string,
    shapeClassName: PropTypes.string,
    embedClassName: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    autoPlay: false,
    playing: false,
    muted: false,
    loading: 'lazy',
    withoutSize: false,
    cover: false,
    controls: true,
    controlsCompact: false,
    controlsOver: false,
    controlsTheme: null,
    nativeControls: false,
    onPlay: null,
    onPause: null,
    onEnded: null,
    onMute: null,
    onUnmute: null,
    onProgress: null,
    onClick: null,
    onClickInfo: null,
    className: null,
    shapeClassName: null,
    embedClassName: null,
};

function Video({
    media,
    width,
    height,
    autoPlay,
    playing,
    muted,
    loading,
    withoutSize,
    cover,
    controls,
    controlsCompact,
    controlsOver,
    controlsTheme,
    nativeControls,
    onPlay,
    onPause,
    onEnded,
    onMute,
    onUnmute,
    onProgress,
    onClick,
    onClickInfo,
    className,
    shapeClassName,
    embedClassName,
}) {
    const [location] = useLocation();
    const isLazyLoading = loading === 'lazy';
    const { ref: refVisible, visible: isVisible } = useIsVisible({
        rootMargin: '200px',
        persist: true,
        disabled: !isLazyLoading,
    });

    const shouldBeEmbedded = !isLazyLoading || isVisible;

    const { ref, id: mediaId, embed = null, url: mediaUrl = null, sources = null } = media;
    const { provider = null, iframeUrl = null, html = null } = embed || {};
    const iframeTitle =
        html !== null && html.match(/title="[^"]+"/) !== null
            ? html.replace(/^.*title="([^"]+)".*$/, '$1')
            : null;

    const baseUri = useRequestBase();

    const finalIframeUrl = useMemo(() => {
        if (provider === 'youtube' && iframeUrl !== null) {
            const queryStringIndex = iframeUrl.indexOf('?');
            const query =
                queryStringIndex !== -1
                    ? queryString.parse(iframeUrl.substr(queryStringIndex) + 1)
                    : {};
            return `${
                queryStringIndex !== -1 ? iframeUrl.substr(0, iframeUrl.indexOf('?')) : iframeUrl
            }?${queryString.stringify({
                ...query,
                cc_load_policy: muted ? 1 : 0,
                iv_load_policy: 3,
                playsinline: 1,
                autoplay: autoPlay && playing ? 1 : 0,
                enablejsapi: 1,
                rel: 0,
                controls: nativeControls ? 1 : 0,
                origin: baseUri,
                widget_referrer: `${baseUri}${location}`,
            })}`;
        }
        return iframeUrl;
    }, [iframeUrl, provider, autoPlay, baseUri, location]);

    // Video player
    const videoPlayer =
        useVideoPlayer({
            service: provider,
            url: shouldBeEmbedded ? iframeUrl || mediaUrl : null,
            embedPlayerId: 'xw15e',
            autoplay: autoPlay,
            controls: nativeControls,
            muted,
            width,
            height,
            onPlay,
            onPause,
            onEnd: onEnded,
        }) || {};

    const {
        ref: refEmbed = null,
        playing: playerPlaying = false,
        muted: playerMuted = muted,
        currentTime,
        duration,
        play = null,
        pause = null,
        mute = null,
        unmute = null,
        seek = null,
        ready = false,
        loaded = false,
    } = videoPlayer || {};

    useVideoTracking(videoPlayer, {
        provider,
        id: mediaId,
        title: iframeTitle,
        url: mediaUrl,
        onProgress,
    });

    const [initialMute, setInitialMute] = useState(false);
    const [initialUnmute, setInitialUnmute] = useState(false);

    useEffect(() => {
        if (playerMuted && onMute !== null) {
            onMute();
        } else if (!playerMuted && onUnmute !== null) {
            onUnmute();
        }
    }, [playerMuted]);

    useEffect(() => {
        if (!ready || playerPlaying === playing || !loaded) {
            return;
        }
        if (!initialMute && playing) {
            mute();
            setInitialMute(true);
        }
        if (playing && play !== null) {
            play();
        } else if (!playing && pause !== null) {
            pause();
        }
    }, [ready, loaded, playing, play, pause]);

    const onClickBlock = useCallback(() => {
        const shouldBeUnmuted = playerMuted && !initialUnmute;
        if (shouldBeUnmuted) {
            unmute();
            setInitialUnmute(true);
        }
        let newPlaying = playerPlaying;
        if (!playerPlaying && play !== null) {
            play();
            newPlaying = true;
        } else if (playerPlaying && !shouldBeUnmuted && pause !== null) {
            pause();
            newPlaying = false;
        }
        if (onClick !== null) {
            onClick(newPlaying);
        }
    }, [playerMuted, playerPlaying, play, pause, initialUnmute, onClick]);

    const onClickPlay = useCallback(() => {
        play();
        if (onClick !== null) {
            onClick(true);
        }
    }, [play, onClick]);

    const onClickPause = useCallback(() => {
        pause();
        if (onClick !== null) {
            onClick(false);
        }
    }, [pause, onClick]);

    const onClickMute = useCallback(() => {
        mute();
    }, [mute]);

    const onClickUnmute = useCallback(() => {
        unmute();
    }, [unmute]);

    const onSeek = useCallback(
        (newTime) => {
            seek(newTime);
        },
        [seek],
    );

    // Get the iframe
    let embedElement = null;
    if (finalIframeUrl !== null) {
        embedElement = (
            <iframe
                src={finalIframeUrl}
                ref={refEmbed}
                className={classNames([
                    styles.embed,
                    {
                        [embedClassName]: embedClassName !== null,
                    },
                ])}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embed"
            />
        );
    } else if (html !== null) {
        embedElement = (
            <div
                className={classNames([
                    styles.embed,
                    {
                        [embedClassName]: embedClassName !== null,
                    },
                ])}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    } else if (mediaUrl !== null) {
        embedElement = (
            <video
                ref={refEmbed}
                className={classNames([
                    styles.embed,
                    {
                        [embedClassName]: embedClassName !== null,
                    },
                ])}
                autoPlay={autoPlay}
                muted={playerMuted}
                playsInline
            >
                {(sources || [])
                    .filter(({ mime }) => (mime || '').match(/^video\//) !== null)
                    .sort(({ size: sizeA }, { size: sizeB }) => {
                        if (sizeA === sizeB) {
                            return 0;
                        }
                        return sizeA < sizeB ? -1 : 1;
                    })
                    .map(({ url, mime }) => (
                        <source key={url} src={`${url}#t=0.001`} type={mime} />
                    ))}
            </video>
        );
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.cover]: cover,
                    [styles.controlsOver]: controlsOver,
                    [className]: className !== null,
                },
            ])}
            style={
                !withoutSize
                    ? {
                          width,
                          height,
                      }
                    : null
            }
            ref={refVisible}
        >
            <div
                className={classNames([
                    styles.shape,
                    {
                        [shapeClassName]: shapeClassName !== null,
                    },
                ])}
                style={
                    !withoutSize
                        ? {
                              width,
                              height,
                          }
                        : null
                }
            >
                <button type="button" onClick={onClickBlock} className={styles.blockButton} />
                {shouldBeEmbedded ? embedElement : null}
            </div>
            {controls && !nativeControls ? (
                <Controls
                    playing={playerPlaying}
                    muted={playerMuted}
                    currentTime={currentTime}
                    duration={duration}
                    compact={controlsCompact}
                    theme={controlsTheme}
                    className={classNames([
                        styles.controls,
                        {
                            [styles.compact]: controlsCompact,
                        },
                    ])}
                    onPlay={onClickPlay}
                    onPause={onClickPause}
                    onMute={onClickMute}
                    onUnmute={onClickUnmute}
                    onSeek={onSeek}
                    onClickInfo={onClickInfo}
                />
            ) : null}
        </div>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
