/* eslint-disable react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearch } from 'wouter';

import { usePromotions } from '../../contexts/PromotionsContext';
import * as ModalsComponents from './modals';
import * as NotificationsComponents from './notifications';

const propTypes = {
    minimumDelayBetween: PropTypes.number,
};

const defaultProps = {
    minimumDelayBetween: 30 * 1000,
};

function PromotionsPopups({ minimumDelayBetween }) {
    const { promotions: activePromotions = [], allPromotions = [], markAsDone } = usePromotions();
    const [currentPromotion, setCurrentPromotion] = useState(null);
    const currentPromotionRef = useRef(currentPromotion);
    const [lastPromotionTime, setLastPromotionTime] = useState(null);

    const tracking = useTracking();
    const search = useSearch();
    const { promotion_popup: promotionPopupQuery = null } = useMemo(
        () => queryString.parse(search),
        [search],
    );

    const promotions = useMemo(
        () =>
            activePromotions.filter(
                ({ placement = null, trigger = null }) =>
                    ['notification', 'modal'].indexOf((placement || '').split(':')[0]) !== -1 &&
                    trigger !== null,
            ),
        [activePromotions],
    );

    const updateCurrentPromotion = useCallback(
        (newPromotion) => {
            setCurrentPromotion(newPromotion);
            if (newPromotion === null && currentPromotionRef.current !== null) {
                setLastPromotionTime(new Date().getTime());
            }
            currentPromotionRef.current = newPromotion;
        },
        [setCurrentPromotion, setLastPromotionTime],
    );

    useEffect(() => {
        const queryPromotion =
            promotionPopupQuery !== null
                ? allPromotions.find(
                      ({ id, handle }) =>
                          handle === promotionPopupQuery || id === promotionPopupQuery,
                  ) || null
                : null;
        if (queryPromotion !== null) {
            updateCurrentPromotion(queryPromotion);
        }
    }, [allPromotions, promotionPopupQuery, updateCurrentPromotion]);

    useEffect(() => {
        if (
            currentPromotion !== null &&
            promotions.map(({ id }) => id).indexOf(currentPromotion.id) === -1
        ) {
            updateCurrentPromotion(null);
        }
    }, [currentPromotion, promotions, updateCurrentPromotion]);

    useEffect(() => {
        const currentTriggers = promotions.map(({ id }) => `promotion_popup_${id}`);
        tracking.triggers.getRegistered().forEach((trigger) => {
            if (
                trigger.match(/^promotion_popup_/) !== null &&
                currentTriggers.indexOf(trigger) === -1
            ) {
                tracking.triggers.unregister(trigger);
            }
        });
        promotions
            .filter(({ id }) => !tracking.triggers.isRegistered(`promotion_popup_${id}`))
            .forEach((promotion) => {
                const { id, trigger, force = false, priority = 0 } = promotion;
                tracking.triggers.register(`promotion_popup_${id}`, trigger, () => {
                    const now = new Date().getTime();
                    const elapsed = lastPromotionTime !== null ? now - lastPromotionTime : Infinity;
                    const { priority: currentPriority = 0 } = currentPromotionRef.current || {};
                    // console.log('kill me', id, trigger, force, priority);
                    if (
                        (currentPromotionRef.current === null &&
                            (minimumDelayBetween === null || elapsed >= minimumDelayBetween)) ||
                        (force && priority > currentPriority)
                    ) {
                        updateCurrentPromotion(promotion);
                    }
                });
            });
        return () => {};
    }, [tracking, promotions, minimumDelayBetween, lastPromotionTime]);

    useEffect(
        () => () => {
            promotions.forEach(({ id }) => {
                tracking.triggers.unregister(`promotion_popup_${id}`);
            });
        },
        [],
    );

    useEffect(() => {
        if (currentPromotion !== null) {
            tracking.trackPromotion(currentPromotion, 'impression');
        }
    }, [currentPromotion]);

    const onClosed = useCallback(() => {
        const { id: currentId = null, force = false } = currentPromotion || {};
        updateCurrentPromotion(null);
        if (!force) {
            markAsDone(currentId);
        }
    }, [currentPromotion, markAsDone]);

    if (currentPromotion === null) {
        return null;
    }

    const { placement = null, type = null } = currentPromotion;
    const [, placementType = null] = (placement || '').split(':');

    const PromotionComponent = getComponentFromName(
        placement === 'notification' ? NotificationsComponents : ModalsComponents,
        placementType || type,
        'promotion',
    );

    return PromotionComponent !== null ? (
        <PromotionComponent promotion={currentPromotion} onClosed={onClosed} />
    ) : null;
}

PromotionsPopups.propTypes = propTypes;
PromotionsPopups.defaultProps = defaultProps;

export default PromotionsPopups;
