import { useUser } from '@folklore/auth';
import { useTracking } from '@folklore/tracking';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useApi } from '../contexts/ApiContext';
import { useIsCheckingAuth } from '../contexts/AuthContext';
import { usePopupsContext } from '../contexts/PopupsContext';
import { useSubscriptions } from '../contexts/SiteContext';
import { updateAuthUser } from './useAuth';

export function useSubscribe(defaultSubscription = null) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationKey: ['subscribe', defaultSubscription],
        mutationFn: ({ subscription = defaultSubscription, ...data }) =>
            api.subscriptions.subscribe(subscription || defaultSubscription, data),
        onSuccess: (response) => {
            queryClient.setQueryData(['auth'], response);
        },
    });

    return {
        subscribe: mutate,
        subscribeAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useUnsubscribe(defaultSubscription = null) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationKey: ['unsubscribe', defaultSubscription],
        mutationFn: (handle) => api.subscriptions.unsubscribe(handle || defaultSubscription),
        onSuccess: (newUser) => {
            updateAuthUser(queryClient, newUser);
        },
    });

    return {
        unsubscribe: mutate,
        unsubscribeAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useSubscribed(subscription) {
    const user = useUser();
    const { subscriptions = [] } = user || {};
    return (
        (subscriptions || []).findIndex(
            ({ id, subscribed = false }) => id === subscription && subscribed,
        ) !== -1
    );
}

export function useSubscription(opts) {
    const {
        subscription: handle = null,
        source = null,
        onSubscribe = null,
        onUnsubscribe = null,
        withoutMessages = false,
        withoutEditContact = false,
    } = opts || {};
    const isCheckingAuth = useIsCheckingAuth();
    const allSubscriptions = useSubscriptions();
    const { addMessage } = usePopupsContext();

    const subscription =
        allSubscriptions.find(({ handle: subscriptionHandle }) => subscriptionHandle === handle) ||
        null;
    const { subscriptions: userSubscriptions = [], phone = null, email = null } = useUser() || {};
    const { subscribed = false, via: userVia } = userSubscriptions.find(
        ({ id }) => id === handle,
    ) || { subscribed: isCheckingAuth ? null : false };

    const { subscribeAsync } = useSubscribe(handle);
    const { unsubscribeAsync } = useUnsubscribe(handle);
    const isSubscribing = useIsMutating({
        mutationKey: ['subscribe', handle],
    });
    const isUnsubscribing = useIsMutating({
        mutationKey: ['unsubscribe', handle],
    });
    const [editContactNeeded, setEditContactNeeded] = useState(null);
    const editContactSubscribeData = useRef(null);
    const editContactResolve = useRef(null);

    const [subscriptionAfterAuth, setSubscriptionAfterAuth] = useState(null);
    const tracking = useTracking();
    const updateSubscription = useCallback(
        (newSubscribed, data = null) => {
            if (isCheckingAuth) {
                return new Promise((resolve) => {
                    setSubscriptionAfterAuth({
                        subscribed: newSubscribed,
                        data,
                        resolve,
                    });
                });
            }
            const {
                subscription: dataSubscription = null,
                subscriptions: dataSubscriptions = [],
                phone: dataPhone = phone,
                email: dataEmail = email,
                via: dataVia = null,
            } = data || {};
            const subscriptions = allSubscriptions.filter(
                ({ handle: subscriptionHandle }) =>
                    subscriptionHandle === handle ||
                    subscriptionHandle === dataSubscription ||
                    (dataSubscriptions || []).indexOf(subscriptionHandle) !== -1,
            );
            const lonelyVia = subscriptions
                .map(({ via }) => via || [])
                .filter((via) => via.length === 1)
                .map((via) => via[0]);
            if (
                newSubscribed &&
                lonelyVia.indexOf('sms') !== -1 &&
                dataPhone === null &&
                !withoutEditContact
            ) {
                setEditContactNeeded('phone');
                return new Promise((resolve) => {
                    editContactSubscribeData.current = data;
                    editContactResolve.current = resolve;
                });
            }
            if (
                newSubscribed &&
                lonelyVia.indexOf('email') !== -1 &&
                dataEmail === null &&
                !withoutEditContact
            ) {
                setEditContactNeeded('email');
                return new Promise((resolve) => {
                    editContactSubscribeData.current = data;
                    editContactResolve.current = resolve;
                });
            }

            return newSubscribed
                ? subscribeAsync({
                      source,
                      ...data,
                  }).then((response) => {
                      const { user: newUser, isNewSubscribed = true } = response;
                      if (!withoutMessages) {
                          addMessage(isNewSubscribed ? 'subscribe' : 'already_subscribed', {
                              user: newUser,
                              subscription: subscriptions.length === 1 ? subscriptions[0] : null,
                          });
                      }
                      subscriptions.forEach(({ handle: subscriptionHandle }) => {
                          tracking.trackSubscribe(subscriptionHandle, source);
                      });
                      if (onSubscribe !== null) {
                          onSubscribe();
                      }
                      return response;
                  })
                : unsubscribeAsync(data).then((newUser) => {
                      if (!withoutMessages) {
                          addMessage('unsubscribe', {
                              subscription: subscriptions.length === 1 ? subscriptions[0] : null,
                          });
                      }
                      subscriptions.forEach(({ handle: subscriptionHandle }) => {
                          tracking.trackUnsubscribe(subscriptionHandle, source);
                      });
                      if (onUnsubscribe !== null) {
                          onUnsubscribe();
                      }
                      return newUser;
                  });
        },
        [
            isCheckingAuth,
            handle,
            allSubscriptions,
            source,
            phone,
            email,
            subscribeAsync,
            unsubscribeAsync,
            onSubscribe,
            onUnsubscribe,
            tracking,
            allSubscriptions,
            addMessage,
            withoutMessages,
            withoutEditContact,
        ],
    );

    useEffect(() => {
        if (!isCheckingAuth && subscriptionAfterAuth !== null) {
            const { subscribed: newSubscribed, data, resolve } = subscriptionAfterAuth;
            updateSubscription(newSubscribed, data).then((newUser) => {
                resolve(newUser);
                return newUser;
            });
            setSubscriptionAfterAuth(null);
        }
    }, [isCheckingAuth, subscriptionAfterAuth, updateSubscription]);

    const subscribe = useCallback((data) => updateSubscription(true, data), [updateSubscription]);
    const unsubscribe = useCallback(
        (data) => updateSubscription(false, data),
        [updateSubscription],
    );

    const updateVia = useCallback(
        (newVia) => {
            if (newVia === 'sms' && phone === null) {
                setEditContactNeeded('phone');
                return;
            }
            if (newVia === 'email' && email === null) {
                setEditContactNeeded('email');
                return;
            }
            subscribeAsync({
                source,
                via: newVia,
            }).then(() => {
                if (onSubscribe !== null) {
                    onSubscribe();
                }
            });
        },
        [handle, source, subscribeAsync, onSubscribe, phone, email],
    );
    const onEditContactComplete = useCallback(
        ({ phone: newPhone, email: newEmail }) => {
            updateSubscription(true, {
                via: editContactNeeded === 'phone' ? 'sms' : 'email',
                phone: newPhone,
                email: newEmail,
                ...editContactSubscribeData.current,
            }).then((newUser) => {
                if (editContactResolve.current !== null) {
                    editContactResolve.current(newUser);
                }
            });
        },
        [updateSubscription, editContactNeeded],
    );

    const closeEditContact = useCallback(() => {
        setEditContactNeeded(null);
        editContactResolve.current = null;
        editContactSubscribeData.current = null;
    }, [setEditContactNeeded]);

    return {
        subscription,
        subscribed,
        via: userVia,
        phone,
        email,
        updateVia,
        subscribe,
        unsubscribe,
        updateSubscription,
        isSubscribing,
        isUnsubscribing,
        editContactNeeded,
        onEditContactComplete,
        closeEditContact,
    };
}
