import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';
import { updateAuthAudiences, useAuthCheck } from './useAuth';

export function useVisitor(opts = null) {
    const authUser = useAuthCheck(opts);

    return {
        visitorId: null,
        audiences: null,
        isNew: false,
        ...authUser,
    };
}

export function useAddAudiences() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (audiences) => api.visitor.addAudiences(audiences),
        onSuccess: (newAudiences) => {
            updateAuthAudiences(queryClient, newAudiences);
        },
    });

    return {
        addAudiences: mutate,
        addAudiencesSync: mutateAsync,
        ...mutationResult,
    };
}
