/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useWindowScroll } from '@folklore/hooks';
import { useTracking } from '@folklore/tracking';
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import useDocument, { useDocumentPreview } from '../../hooks/useDocument';
import useElementSize from '../../hooks/useElementSize';
import useOnClickLink from '../../hooks/useOnClickLink';
import useScrollEnter from '../../hooks/useScrollEnter';
import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import {
    useDocumentPageViewTracking,
    useTrackLink,
    useTrackOnClickCard,
} from '../../hooks/useTracking';
import { useDocumentTypeFromPath, useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
import {
    createAuthorRow,
    createCollectionRow,
    createLocationsList,
    createRelatedDocumentsRow,
} from '../../lib/blocks';
import getBlocksWithAddons from '../../lib/getBlocksWithAddons';

import { DocumentAdsTargetingProvider } from '../../contexts/AdsTargeting';
import { useHasModals } from '../../contexts/PopupsContext';
import { useRequestBase } from '../../contexts/RequestContext';
import { useVisitorId } from '../../contexts/VisitorContext';
import Ad from '../ads/Ad';
import Blocks from '../blocks/Blocks';
import * as Documents from '../documents/index';
import DocumentDefaultHeader from '../headers/DocumentDefaultHeader';
import DocumentMetadata from '../partials/DocumentMetadata';
// import DocumentsList from '../lists/DocumentsList';
import LoadingBar from '../partials/LoadingBar';
import Body from '../typography/Body';
import ListTitle from '../typography/ListTitle';
import SummaryTitle from '../typography/SummaryTitle';
import NotFoundPage from './NotFoundPage';

import styles from '../../styles/pages/document-page.module.css';

const propTypes = {
    slug: PropTypes.string,
    previewToken: PropTypes.string,
    paused: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    slug: null,
    previewToken: null,
    paused: false,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    disabled: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function DocumentPage({
    slug,
    previewToken,
    paused,
    current,
    entered,
    entering,
    leaving,
    disabled,
    className,
    contentClassName,
    onEnter,
    onLeave,
}) {
    const hasModals = useHasModals();
    const {
        document: currentDocument = null,
        notFound = false,
        isFetching = false,
    } = previewToken !== null
        ? useDocumentPreview(previewToken, {
              keepPreviousData: false,
              enabled: !disabled,
          })
        : useDocument(slug, {
              keepPreviousData: false,
              enabled: !disabled,
          });

    // Current document
    const {
        id = null,
        type = null,
        title = null,
        subtitle = null,
        metadata = null,
        blocks = null,
        theme: documentTheme = null,
    } = currentDocument || {};

    const {
        credits,
        sponsors,
        brands,
        categories,
        locations = null,
        publishedAt,
        collections = [],
    } = metadata || {};

    const { withoutFooter = false, fullscreen = false } = documentTheme || {};

    const [location] = useLocation();
    const url = useDocumentUrlGenerator();
    const baseUri = useRequestBase();
    const visitorId = useVisitorId();
    const [{ author = null } = {}] = credits || [];
    const [brand = null] = brands || [];
    const [collection = null] = collections || [];
    const { handle: brandHandle = null } = brand || {};
    const {
        slug: authorSlug = null,
        isPerson: authorIsPerson = false,
        id: authorId = null,
    } = author || {};
    const locationType = useDocumentTypeFromPath(location);
    const finalDocumentType = type || locationType || null;
    const documentUrl =
        slug !== null && type !== null
            ? `${baseUri}${url({
                  type,
                  slug,
              })}`
            : null;
    const DocumentComponent = getComponentFromName(Documents, finalDocumentType, null);

    const { ref: metadataRef, height: metadataHeight } = useElementSize();
    const { ref: documentContainerRef, height: documentHeight = 0 } = useElementSize();
    const { ref: metadataContentRef, height: metadataContentHeight = 0 } = useElementSize();

    const placeholder = currentDocument === null;

    const surtitle = useDocumentSurtitle(currentDocument);

    const documentBlocks = useMemo(
        () => [
            ...getBlocksWithAddons(
                placeholder
                    ? [
                          {
                              id: 'placeholder-1',
                              type: 'placeholder',
                          },
                          {
                              id: 'placeholder-2',
                              type: 'placeholder',
                          },
                      ]
                    : blocks || [],
                {
                    addonsProps: {
                        ad: {
                            disabled: !current || placeholder,
                            slot: 'article',
                        },
                    },
                },
            ),
            locations !== null && locations.length > 0
                ? createLocationsList(locations, null, {
                      cardClassName: styles.locationCard,
                      cardProps: {
                          iconClassName: styles.locationIcon,
                      },
                  })
                : null,
        ],
        [blocks, locations, placeholder, current],
    );

    const onClickRelatedCard = useTrackOnClickCard('click_related');

    const { y: scrollY = 0 } = useWindowScroll();
    const footerContentVisible = current && (((entering || entered) && !leaving) || scrollY > 10);
    const isFullscreen =
        fullscreen || finalDocumentType === 'video' || finalDocumentType === 'micromag';
    const footerAdsDisabled = !footerContentVisible || (isFullscreen && entered);

    const footerBlocks = useMemo(
        () =>
            [
                collection !== null && id !== null
                    ? {
                          type: 'ad',
                          slot: 'fullwidth',
                          disabled: footerAdsDisabled,
                      }
                    : null,
                collection !== null
                    ? createCollectionRow((collection.serie || {}).slug || collection.slug, {
                          query: {
                              exclude_id: id,
                          },
                          cardSize: null,
                          cardTheme: 'box-white',
                          cardWidth: 200,
                          presentation: 'grid',
                          clickRef: 'document-footer-collection',
                          count: 12,
                          summaryPosition: null,
                          loading: 'lazy',
                          //   withoutSummaryImage: true,
                          //   withBackground: true,
                          withBorderRadius: false,
                          cardWithout: ['serie', 'collection'],
                          className: classNames([styles.row, styles.flush]),
                          innerClassName: styles.inner,
                          summaryClassName: styles.summary,
                          summaryButtonClassName: styles.summaryButton,
                      })
                    : null,
                authorSlug !== null && authorIsPerson
                    ? createAuthorRow(authorSlug, {
                          query: {
                              exclude_id: id,
                          },
                          cardSize: null,
                          cardTheme: 'rounded',
                          cardWidth: 250,
                          clickRef: 'document-footer-author',
                          count: 6,
                          presentation: 'grid',
                          summaryPosition: null,
                          background: null,
                          loading: 'lazy',
                          className: styles.row,
                          innerClassName: styles.inner,
                          summaryClassName: styles.summary,
                          summaryButtonClassName: styles.summaryButton,
                      })
                    : null,
                id !== null
                    ? {
                          type: 'ad',
                          slot: 'fullwidth',
                          disabled: footerAdsDisabled,
                      }
                    : null,
                id !== null && visitorId !== null
                    ? createRelatedDocumentsRow(
                          id,
                          {
                              referrer: documentUrl,
                              visitor_id: visitorId,
                              ...(authorIsPerson && authorId !== null
                                  ? { exclude_author: authorId }
                                  : {}),
                          },
                          {
                              header: (
                                  <ListTitle className={styles.title}>
                                      <FormattedMessage defaultMessage="Plus de contenus" />
                                  </ListTitle>
                              ),
                              presentation: 'grid',
                              cardSize: null,
                              cardTheme: 'rounded',
                              cardWidth: 250,
                              clickRef: 'document-footer-related',
                              onClickCard: onClickRelatedCard,
                              count: 12,
                              loading: 'lazy',
                              summaryPosition: null,
                              background: null,
                              className: styles.row,
                              innerClassName: styles.inner,
                          },
                      )
                    : null,
                id !== null
                    ? {
                          type: 'ad',
                          slot: 'fullwidth',
                          disabled: footerAdsDisabled,
                      }
                    : null,
            ].filter((it) => it !== null),
        [authorSlug, authorIsPerson, id, collection, documentUrl, visitorId, footerAdsDisabled],
    );

    const isArticle =
        finalDocumentType === 'article' ||
        finalDocumentType === 'contest' ||
        finalDocumentType === 'article_legacy';

    useScrollEnter({
        leave: current && scrollY > documentHeight && isArticle,
        disabled: !current || (current && isFullscreen),
        leaveThreshold:
            documentHeight > 0 && metadataContentHeight > 0
                ? documentHeight + metadataContentHeight
                : null,
        onEnter,
        onLeave,
    });

    // useEffect(() => {
    //     if (current && isFullscreen && onEnter !== null) {
    //         onEnter();
    //     }
    // }, [current, isFullscreen, onEnter]);

    const tracking = useTracking();
    const trackingDisabled = !current || currentDocument === null || previewToken !== null;
    useDocumentPageViewTracking(!trackingDisabled, currentDocument);

    const onDocumentProgress = useCallback(
        (progress) => {
            if (trackingDisabled) {
                return;
            }
            tracking.trackEvent('Document', 'progress', title, null, {
                documentProgress: progress * 100,
                document: currentDocument,
            });
        },
        [trackingDisabled, tracking, id],
    );

    const onDocumentComplete = useCallback(() => {
        if (trackingDisabled) {
            return;
        }
        tracking.trackEvent('Document', 'complete', title, null, {
            documentProgress: 100,
            document: currentDocument,
        });
    }, [trackingDisabled, tracking, id]);

    const trackLink = useTrackLink();
    const onClickLink = useCallback(
        ({ url: linkUrl, external, label }) => {
            if (external) {
                trackLink(linkUrl, {
                    linkType: 'article',
                    linkLabel: label,
                });
            }
        },
        [trackLink],
    );
    const onClickBody = useOnClickLink(onClickLink);

    if (notFound) {
        return <NotFoundPage current={current} />;
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isLoading]: isFetching,
                    [styles.current]: current,
                    [styles.entered]: (entering || entered) && !leaving,
                    [styles.leaved]: (!entering && !entered) || leaving,
                    [styles.withoutFooter]: withoutFooter,
                    [styles.footerContentVisible]: footerContentVisible,
                    [styles.isFullscreen]: isFullscreen,
                },
                className,
            ])}
            data-brand={brandHandle}
        >
            <DocumentAdsTargetingProvider
                document={currentDocument}
                disabled={!current || currentDocument === null}
            >
                <LoadingBar
                    loading={currentDocument === null}
                    loaded={currentDocument !== null}
                    className={styles.loadingBar}
                />
                {DocumentComponent !== null ? (
                    <>
                        <div className={styles.documentContainer} ref={documentContainerRef}>
                            <Suspense fallback={<div className={styles.document} />}>
                                {/* <ArticleLegacyDocument /> */}
                                <DocumentComponent
                                    {...currentDocument}
                                    current={current}
                                    entered={entered}
                                    entering={entering}
                                    leaving={leaving}
                                    onEnter={onEnter}
                                    onLeave={onLeave}
                                    paused={!current || hasModals || paused}
                                    placeholder={placeholder}
                                    isPreview={previewToken !== null}
                                    onComplete={onDocumentComplete}
                                    onProgress={onDocumentProgress}
                                    className={classNames([
                                        styles.document,
                                        styles[finalDocumentType],
                                        {
                                            [styles.smaller]: metadataHeight > 60 && !isArticle,
                                        },
                                        contentClassName,
                                    ])}
                                />
                            </Suspense>
                        </div>
                        <div
                            className={classNames([
                                styles.footer,
                                {
                                    [styles.hasContent]: !isArticle,
                                },
                            ])}
                            data-theme="dark"
                        >
                            {!isArticle ? (
                                <>
                                    <div className={styles.metadata} ref={metadataRef}>
                                        <SummaryTitle
                                            className={styles.title}
                                            placeholder={placeholder}
                                        >
                                            {title}
                                        </SummaryTitle>
                                    </div>
                                    <div
                                        className={styles.content}
                                        ref={metadataContentRef}
                                        data-theme="normal"
                                    >
                                        <DocumentDefaultHeader
                                            surtitle={surtitle}
                                            title={title}
                                            subtitle={subtitle}
                                            credits={credits}
                                            brand={brand}
                                            sponsors={sponsors}
                                            categories={categories}
                                            date={publishedAt}
                                            placeholder={placeholder}
                                            withoutImage
                                            className={styles.header}
                                        />
                                        <div className={styles.topAdContainer}>
                                            <Ad
                                                slot="fullwidth"
                                                disabled={footerAdsDisabled}
                                                className={styles.topAd}
                                            />
                                        </div>
                                        <Body className={styles.body} onClick={onClickBody}>
                                            <Blocks blocks={documentBlocks} />
                                        </Body>
                                        {metadata !== null ? (
                                            <DocumentMetadata
                                                {...metadata}
                                                className={styles.documentMetadata}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            ) : null}

                            <Blocks blocks={footerBlocks} blockClassName={styles.block} />
                        </div>
                    </>
                ) : (
                    <div className={styles.document} />
                )}
            </DocumentAdsTargetingProvider>
        </div>
    );
}

DocumentPage.propTypes = propTypes;
DocumentPage.defaultProps = defaultProps;

export default DocumentPage;
