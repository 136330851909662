/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import ContestForm from '../forms/ContestForm';

import styles from '../../styles/blocks/contest-block.module.css';
import FormDescription from '../typography/FormDescription';

const propTypes = {
    contest: AppPropTypes.contest.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ContestBlock = ({ contest, className }) => {
    const { title = null, startAt = null, endAt = null } = contest;
    const startAtDate = useMemo(() => startAt !== null ? parseISO(startAt) : null, [startAt]);
    const endAtDate = useMemo(() => endAt !== null ? parseISO(endAt) : null, [startAt]);
    const isComing = useMemo(() => startAtDate !== null && isBefore(new Date(), startAtDate), [startAtDate]);
    const isEnded = useMemo(() => endAtDate !== null && isAfter(new Date(), endAtDate), [endAtDate]);
    const isStarted = !isComing && !isEnded;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {isStarted ? <ContestForm contest={contest} withEmail /> : null}
                {isComing || isEnded ? (
                    <FormDescription className={styles.wait}>
                        {isComing ? (
                            <FormattedMessage
                                defaultMessage="Le {title} commence le <strong>{date}</strong>."
                                description="Contest wait message"
                                values={{
                                    title: title || 'concours',
                                    date: (
                                        <FormattedDate
                                            value={startAtDate}
                                            weekday="long"
                                            day="numeric"
                                            month="long"
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Le {title} est terminé depuis le {date}."
                                description="Contest wait message"
                                values={{
                                    title: title || 'concours',
                                    date: (
                                        <FormattedDate
                                            value={endAtDate}
                                            weekday="long"
                                            day="numeric"
                                            month="long"
                                        />
                                    ),
                                }}
                            />
                        )}
                    </FormDescription>
                ) : null}
            </div>
        </div>
    );
};

ContestBlock.propTypes = propTypes;
ContestBlock.defaultProps = defaultProps;

export default ContestBlock;
