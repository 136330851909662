/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-restricted-globals */
import { AdsTargetingProvider } from '@folklore/ads';
import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { Suspense, useMemo } from 'react';
import { Route, Switch } from 'wouter';

// import { useGlobalBrand } from '../contexts/LayoutContext';
import { useDefaultBrand } from '../contexts/SiteContext';
import StaticRouter from './StaticRouter';
import AccountPage from './pages/AccountPage';
import AllPage from './pages/AllPage';
import ArticlesPage from './pages/ArticlesPage';
import AuthorPage from './pages/AuthorPage';
import CategoryPage from './pages/CategoryPage';
import CollectionPage from './pages/CollectionPage';
import CollectionsPage from './pages/CollectionsPage';
import DocumentPage from './pages/DocumentPage';
import DocumentSharePage from './pages/DocumentSharePage';
import FormatPage from './pages/FormatPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
// import MapPage from './pages/MapPage';
import MicromagsPage from './pages/MicromagsPage';
import NotFoundPage from './pages/NotFoundPage';
import Page from './pages/Page';
import PodcastsPage from './pages/PodcastsPage';
import SearchPage from './pages/SearchPage';
import SectionPage from './pages/SectionPage';
import SubscriptionPage from './pages/SubscriptionPage';
import TopicPage from './pages/TopicPage';
import VideosPage from './pages/VideosPage';

const MapPage = React.lazy(() => import('./pages/MapPage'));

const propTypes = {
    location: PropTypes.string.isRequired,
};

const defaultProps = {};

function PageRoutes({ location, ...props }) {
    // eslint-disable-next-line react/prop-types
    const { current = false, next = false } = props;
    const routes = useRoutes();

    const defaultBrand = useDefaultBrand();
    const adsDisabled = !current && !next;
    const adsTargeting = useMemo(
        () => ({
            brand: defaultBrand !== null ? defaultBrand.handle || defaultBrand.slug : null,
            brands: [defaultBrand].map(({ slug = null, handle = null }) => handle || slug),
            disabled: adsDisabled,
        }),
        [adsDisabled, defaultBrand],
    );

    return (
        <AdsTargetingProvider targeting={adsTargeting}>
            <StaticRouter location={location}>
                <Switch>
                    <Route path={routes['document.share']}>
                        {({ slug }) => <DocumentSharePage slug={slug} {...props} />}
                    </Route>
                    <Route path={routes.document}>
                        {({ slug }) => <DocumentPage slug={slug} {...props} />}
                    </Route>
                    <Route path={routes.preview}>
                        {({ token }) => <DocumentPage previewToken={token} {...props} />}
                    </Route>
                    <Route path={routes.collection}>
                        {({ slug }) => <CollectionPage slug={slug} {...props} />}
                    </Route>
                    <Route path={routes.author}>
                        {({ slug }) => <AuthorPage slug={slug} {...props} />}
                    </Route>

                    <Route path={routes.category}>
                        {({ slug }) => <CategoryPage slug={slug} {...props} />}
                    </Route>
                    <Route path={routes.topic}>
                        {({ slug }) => <TopicPage slug={slug} {...props} />}
                    </Route>
                    <Route path={routes.format}>
                        {({ slug }) => <FormatPage slug={slug} {...props} />}
                    </Route>

                    <Route path={routes.articles}>
                        {({ brand }) => <ArticlesPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.videos}>
                        {({ brand }) => <VideosPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.micromags}>
                        {({ brand }) => <MicromagsPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.podcasts}>
                        {({ brand }) => <PodcastsPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.collections}>
                        {({ brand }) => <CollectionsPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.all}>
                        {({ brand }) => <AllPage brandSlug={brand} {...props} />}
                    </Route>
                    <Route path={routes.popular}>
                        {({ brand }) => <AllPage brandSlug={brand} sort="popular" {...props} />}
                    </Route>
                    <Route path={routes.search}>
                        <SearchPage {...props} />
                    </Route>

                    <Route path={routes.map}>
                        <Suspense fallback={null}>
                            <MapPage {...props} />
                        </Suspense>
                    </Route>
                    <Route path={routes['map.region']}>
                        <Suspense fallback={null}>
                            <MapPage {...props} />
                        </Suspense>
                    </Route>
                    <Route path={routes['map.locationType']}>
                        <Suspense fallback={null}>
                            <MapPage {...props} />
                        </Suspense>
                    </Route>
                    <Route path={routes['map.location']}>
                        <Suspense fallback={null}>
                            <MapPage {...props} />
                        </Suspense>
                    </Route>
                    <Route path={routes['map.document']}>
                        <Suspense fallback={null}>
                            <MapPage {...props} />
                        </Suspense>
                    </Route>

                    <Route path={routes.login}>
                        <LoginPage {...props} />
                    </Route>

                    <Route path={routes.register}>
                        <LoginPage {...props} />
                    </Route>

                    <Route path={routes.account}>
                        <AccountPage {...props} />
                    </Route>
                    <Route path={routes['account.subscriptions']}>
                        <AccountPage {...props} />
                    </Route>
                    <Route path={routes['account.timeline']}>
                        <AccountPage {...props} />
                    </Route>

                    <Route path={routes.section}>
                        {({ brand, section }) => (
                            <SectionPage brandSlug={brand} slug={section} {...props} />
                        )}
                    </Route>

                    <Route path={routes.subscription}>
                        {({ brand }) => <SubscriptionPage brandSlug={brand} {...props} />}
                    </Route>

                    <Route path={routes.subscribe}>
                        {({ brand }) => <SubscriptionPage brandSlug={brand} subscribe {...props} />}
                    </Route>

                    <Route path={routes.home}>
                        {({ brand }) => <HomePage brandSlug={brand} {...props} />}
                    </Route>

                    <Route path={routes.page}>
                        {({ slug }) => <Page slug={slug} {...props} />}
                    </Route>
                    <Route>
                        <NotFoundPage {...props} />
                    </Route>
                </Switch>
            </StaticRouter>
        </AdsTargetingProvider>
    );
}

PageRoutes.propTypes = propTypes;
PageRoutes.defaultProps = defaultProps;

export default React.memo(PageRoutes);
