import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/bump.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    playing: PropTypes.bool,
    onEnded: PropTypes.func,
    onFail: PropTypes.func,
    onPlay: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    playing: false,
    onEnded: null,
    onFail: null,
    onPlay: null,
    className: null,
};

function Bump({ brand, playing, onEnded, onFail, onPlay, className }) {
    const {
        ref,
        entry: { contentRect },
    } = useResizeObserver({});
    const { width: windowWidth = null, height: windowHeight = null } = useWindowSize();
    const { width = windowWidth, height = windowHeight } = contentRect || {};
    const hasSize = width !== null && width > 0 && height !== null && height > 0;
    const mode = width > height ? 'horizontal' : 'vertical';
    const [loaded, setLoaded] = useState(false);
    const [hasPlayed, setHasPlayed] = useState(false);

    const videoRef = useRef(null);

    const { handle, videos } = brand;
    const video = hasSize ? videos[camelCase(`bumper_${mode}`)] : null;

    useEffect(() => {
        if (video === null || videoRef.current === null) {
            return;
        }
        setLoaded(false);
        setHasPlayed(false);
        try {
            if (typeof videoRef.current.load !== 'undefined') {
                videoRef.current.load().catch(() => {});
            }
        } catch (e) {}
    }, [video]);

    useEffect(() => {
        if (video === null || videoRef.current === null) {
            return;
        }
        try {
            if (playing) {
                videoRef.current.currentTime = 0;
                videoRef.current.play().catch((e) => {
                    if (onFail !== null) {
                        onFail();
                    }
                });
            } else {
                videoRef.current.pause().catch(() => {});
            }
        } catch (e) {}
    }, [video, playing]);

    const onVideoEnded = useCallback(() => {
        if (playing) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
        if (onEnded !== null) {
            onEnded();
        }
    }, [video, onEnded, playing]);

    const onVideoCanPlay = useCallback(() => {
        setLoaded(true);
    }, [setLoaded]);

    const onVideoPlaying = useCallback(() => {
        setHasPlayed(true);
    }, [setHasPlayed, onPlay]);

    const onVideoPlay = useCallback(() => {
        setHasPlayed(true);
        if (onPlay !== null) {
            onPlay();
        }
    }, [setHasPlayed]);

    const onVideoSuspend = useCallback(() => {
        if (!hasPlayed && onEnded !== null) {
            onEnded();
        }
    }, [hasPlayed, onEnded]);

    useEffect(() => {
        if (hasPlayed || !playing) {
            return () => {};
        }
        const timeout = setTimeout(() => {
            if (onEnded !== null) {
                onEnded();
            }
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [playing, hasPlayed, onEnded]);

    const { sources = [] } = video || {};
    const sourcesByMime = useMemo(
        () =>
            sources
                .filter(({ mime = '' }) => mime.match(/^video\/(mp4|webm|mpeg)/) !== null)
                .sort(({ size: a = '' }, { size: b = '' }) => {
                    if (a === b) {
                        return 0;
                    }
                    return a > b ? 1 : -1;
                })
                .reduce(
                    (map, source) =>
                        typeof map[source.mime] === 'undefined'
                            ? {
                                  ...map,
                                  [source.mime]: source.url,
                              }
                            : map,
                    {},
                ),
        [sources],
    );

    useEffect(() => {
        if (Object.keys(sourcesByMime).length === 0 && onEnded !== null) {
            onEnded();
        }
    }, [sourcesByMime]);

    return (
        <div
            ref={ref}
            className={classNames([
                styles.container,
                {
                    [styles.loaded]: loaded,
                },
                styles[handle],
                className,
            ])}
        >
            {video !== null ? (
                <video
                    key={mode}
                    ref={videoRef}
                    className={styles.video}
                    preload="auto"
                    muted
                    playsInline
                    autoPlay={playing}
                    onEnded={onVideoEnded}
                    onPlay={onVideoPlay}
                    onPlaying={onVideoPlaying}
                    onCanPlay={onVideoCanPlay}
                    onSuspend={onVideoSuspend}
                >
                    {Object.keys(sourcesByMime).map((mime) => (
                        <source src={`${sourcesByMime[mime]}#t=0.1`} type={mime} />
                    ))}
                </video>
            ) : null}
        </div>
    );
}

Bump.propTypes = propTypes;
Bump.defaultProps = defaultProps;

export default Bump;
