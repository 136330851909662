/* eslint-disable react/jsx-props-no-spreading */
import { getSizeWithinBounds } from '@folklore/size';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useDocuments from '../../hooks/useDocuments';
import useElementSize from '../../hooks/useElementSize';
import useFiltersQuery from '../../hooks/useFiltersQuery';
import useMedia from '../../hooks/useMedia';
import useScrollEnter from '../../hooks/useScrollEnter';
import { usePageViewTracking } from '../../hooks/useTracking';

import { useBrand, useDefaultBrand, useSite } from '../../contexts/SiteContext';
import DocumentOverCard from '../cards/DocumentOverCard';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import Video from '../partials/Video';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/pages/micromags-page.module.css';

import slogan from '../../assets/img/brands/urbania_slogan.svg';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    withTrailer: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    withTrailer: true,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function MicromagsPage({
    brandSlug,
    current,
    withTrailer: initialWithTrailer,
    className,
    contentClassName,
    onEnter,
    onLeave,
}) {
    const hasBrand = brandSlug !== null;
    const { handle: siteHandle } = useSite();
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};
    const filters = useFiltersQuery();
    const { media: trailerMedia = null, isLoading: trailerIsLoading = true } = useMedia(
        'micromag_trailer',
        {
            enabled: initialWithTrailer,
        },
    );
    const withTrailer = initialWithTrailer && (trailerMedia !== null || trailerIsLoading);
    const { items: lastItems = null } = useDocuments({
        type: 'micromag',
        collection: `micromag-${siteHandle}`,
        count: 1,
        enabled: !withTrailer,
    });
    const [lastMicromag = null] = lastItems || [];
    const { width: trailerWidth = 1080, height: trailerHeight = 1920 } = trailerMedia || {};
    const { ref: headerRef, height: headerHeight = 0 } = useElementSize();
    const { ref: playerRef, width: playerWidth = 0, height: playerHeight = 0 } = useElementSize();
    const { width: videoWidth, height: videoHeight } = getSizeWithinBounds(
        withTrailer ? trailerWidth : 4,
        withTrailer ? trailerHeight : 6,
        playerWidth,
        Math.min(playerHeight, headerHeight),
    );

    const { items: featuredItems = null, isLoading: featuredItemsIsLoading = true } = useDocuments({
        placement_only: 'urbania_micromag_featured',
        // count: 9,
    });

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    usePageViewTracking(current, {
        brand,
        pageType: 'micromags',
    });

    const blocks = useMemo(() => [], []);

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
                {
                    [styles.withLastMicromag]: !withTrailer,
                },
            ])}
            data-brand={brandHandle}
        >
            <div className={styles.header} ref={headerRef}>
                {withTrailer ? (
                    <div className={styles.player} ref={playerRef}>
                        {trailerMedia !== null ? (
                            <Video
                                media={trailerMedia}
                                width={videoWidth}
                                height={videoHeight}
                                className={styles.video}
                                controlsCompact
                                controlsOver
                                loading={null}
                                autoPlay={current}
                                playing={current}
                                muted
                            />
                        ) : (
                            <div
                                className={styles.video}
                                style={{
                                    width: videoWidth,
                                    height: videoHeight,
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div className={styles.player} ref={playerRef}>
                        <div className={styles.frame}>
                            <DocumentOverCard
                                {...lastMicromag}
                                ratio="micromag"
                                withoutIcon
                                className={styles.lastMicromag}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.presentation}>
                    <img src={slogan} alt="On vous amène ailleurs" className={styles.slogan} />
                    <div className={styles.description}>
                        <p>
                            Chez URBANIA, on a toujours laissé la curiosité nous guider vers ce qui
                            nous permet de voir le monde autrement. Ce qu’on vous offre, c’est des
                            sujets, des individus et des histoires qui sortent de l’ordinaire, et
                            tout ça, dans le but de vous amener ailleurs.
                        </p>
                        <p>
                            Ça vous parle? Vous aimerez nos Micromags : un contenu exclusif,
                            accompagné du meilleur d’URBANIA. Livrée chaque semaine directement dans
                            votre poche, sur votre mobile, gratuitement.
                        </p>
                    </div>
                </div>
            </div>

            {(featuredItems !== null && featuredItems.length > 0) || featuredItemsIsLoading ? (
                <div className={classNames([styles.section, styles.featured, 'drag-disabled'])}>
                    <SectionTitle className={styles.title}>En vedette</SectionTitle>
                    <div className={styles.items}>
                        {(featuredItems || []).map((item) => (
                            <DocumentOverCard
                                {...item}
                                ratio="micromag"
                                withoutIcon
                                className={styles.item}
                            />
                        ))}
                    </div>
                </div>
            ) : null}
            <div className={styles.section}>
                <SectionTitle className={styles.title}>Derniers numéros</SectionTitle>
                <InfiniteItemsBlocks
                    query={{
                        type: 'micromag',
                        collection: `micromag-${siteHandle}`,
                        ...filters,
                    }}
                    blocks={blocks}
                    itemsBlocks={[
                        {
                            type: 'items',
                            itemsType: 'documents',
                            presentation: 'grid',
                            cardType: 'hybrid',
                            cardRatio: 'micromag',
                            cardTheme: 'outline',
                            // cardSize: 'responsive',
                            cardWidth: 200,
                            cardResponsive: true,
                            // cardSize: 'big',
                            cardWithout: ['brand-icon', 'type-icon', 'time'],
                            cardWith: ['description'],
                            minColumns: 1,
                            count: 24,
                            cardClassName: styles.card,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

MicromagsPage.propTypes = propTypes;
MicromagsPage.defaultProps = defaultProps;

export default MicromagsPage;
