/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useSearch } from 'wouter';

import { useSubscription } from '../../hooks/useSubscription';

import { useSubscriptions } from '../../contexts/SiteContext';
import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import SubscriptionVerticalCard from '../cards/SubscriptionVerticalCard';
import SectionDescription from '../typography/SectionDescription';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/panels/account-subscriptions-panel.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AccountSubscriptionsPanel({ className }) {
    const allSubscriptions = useSubscriptions();

    const search = useSearch();
    const {
        unsubscribe: unsubscribeQuery = null,
        subscribe: subscribeQuery = null,
        source: sourceQuery = null,
    } = useMemo(() => queryString.parse(search) || {}, [search]);
    const { subscribe, unsubscribe } = useSubscription({
        subscription: subscribeQuery || unsubscribeQuery,
        source: sourceQuery || 'link',
    });

    useEffect(() => {
        if (subscribeQuery !== null) {
            subscribe();
        } else if (unsubscribeQuery !== null) {
            unsubscribe();
        }
    }, [unsubscribeQuery, subscribeQuery]);

    const externalSubscriptions = allSubscriptions.filter(({ type }) => type === 'external');

    const brandsNotifications = allSubscriptions.filter(
        ({ type, brand = null }) => type === 'notification' && brand !== null,
    );
    const authorsNotifications = allSubscriptions.filter(
        ({ type, author = null }) => type === 'notification' && author !== null,
    );
    const subscriptionQuery = subscribeQuery || unsubscribeQuery;
    const subscriptions = allSubscriptions
        .filter(({ type }) => type !== 'notification' && type !== 'external')
        .sort(({ handle: aHandle }, { handle: bHandle }) => {
            if (aHandle === subscriptionQuery) {
                return -1;
            }
            if (bHandle === subscriptionQuery) {
                return 1;
            }
            return 0;
        });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <section className={styles.section}>
                <SectionTitle className={styles.title}>Infolettres</SectionTitle>
                <SectionDescription className={styles.description}>
                    Abonnez-vous par sms ou par courriel à nos infolettres
                </SectionDescription>
                <div className={styles.subscriptions}>
                    {subscriptions.map((subscription) => (
                        <SubscriptionHorizontalCard
                            {...subscription}
                            thumbnailWidth={75}
                            source="account"
                            theme="box"
                            withToggle
                            className={styles.subscription}
                        />
                    ))}
                </div>
            </section>

            {authorsNotifications.length > 0 || brandsNotifications.length > 0 ? (
                <section className={styles.section}>
                    <SectionTitle className={styles.title}>Notifications</SectionTitle>
                    <SectionDescription className={styles.description}>
                        Recevez une notification par sms pour chaque contenu publié
                    </SectionDescription>

                    <div className={styles.authors}>
                        {authorsNotifications.map((subscription) => (
                            <SubscriptionHorizontalCard
                                {...subscription}
                                thumbnailWidth={75}
                                source="account"
                                theme="notification"
                                ratio="square"
                                withToggle
                                className={styles.notification}
                            />
                        ))}
                    </div>

                    <div className={styles.brands}>
                        {brandsNotifications.map((subscription) => (
                            <SubscriptionVerticalCard
                                {...subscription}
                                source="account"
                                theme="notification"
                                ratio="square"
                                withToggle
                                className={styles.notification}
                            />
                        ))}
                    </div>
                </section>
            ) : null}

            {externalSubscriptions.length > 0 ? (
                <section className={styles.section}>
                    <SectionTitle className={styles.title}>Infolettres amies</SectionTitle>
                    <SectionDescription className={styles.description}>
                        Ici on se fait un plaisir de vous recommander les infolettres de nos
                        ami.e.s, du contenu intelligent, pertinent et divertissant. Abonnez-vous,
                        vous ne serez pas déçus.
                    </SectionDescription>
                    <div className={styles.subscriptions}>
                        {externalSubscriptions.map((subscription) => (
                            <SubscriptionHorizontalCard
                                {...subscription}
                                thumbnailWidth={75}
                                source="account"
                                theme="box"
                                withToggle
                                className={styles.subscription}
                            />
                        ))}
                    </div>
                </section>
            ) : null}
        </div>
    );
}

AccountSubscriptionsPanel.propTypes = propTypes;
AccountSubscriptionsPanel.defaultProps = defaultProps;

export default AccountSubscriptionsPanel;
