/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useUrlGenerator } from '@folklore/routes';
import { getComponentFromName } from '@folklore/utils';
import { Widget } from '@niche-js/core/components';
import { useIsEditor } from '@niche-js/core/editor/contexts';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import * as AnimationComponents from '../../animations';
import Author from '../partials/Author';
import Category from '../partials/Category';
import Date from '../partials/Date';
import Image from '../partials/Image';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import DocumentMetadata from '../typography/DocumentMetadata';
import DocumentSubtitle from '../typography/DocumentSubtitle';
import DocumentTitle from '../typography/DocumentTitle';

import styles from '../../styles/headers/document-default-header.module.css';

const propTypes = {
    sponsors: AppPropTypes.sponsors,
    brand: AppPropTypes.brand,
    date: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    surtitle: PropTypes.string,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    placeholder: PropTypes.bool,
    animation: PropTypes.string,
    withoutImage: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    date: null,
    title: null,
    subtitle: null,
    surtitle: null,
    image: null,
    coverImage: null,
    categories: null,
    credits: null,
    sponsors: null,
    placeholder: false,
    animation: null,
    withoutImage: false,
    className: null,
};

function DocumentDefaultHeader({
    title,
    surtitle,
    subtitle,
    brand,
    image,
    categories,
    credits,
    date,
    sponsors,
    placeholder,
    animation,
    withoutImage,
    className,
}) {
    const isEditor = useIsEditor();
    // const url = useUrlGenerator();
    const [category = null] = categories || [];
    const [{ author = null } = {}] = credits || [];
    const { isPerson: authorIsPerson = false } = author || {};

    const { handle: brandHandle } = brand || {};
    const [sponsor = null] = sponsors || [];

    const withBefore = category !== null || surtitle !== null || isEditor;

    const withAfter =
        author !== null || date !== null || subtitle !== null || sponsor !== null || isEditor;

    const hasAuthor = (author !== null && authorIsPerson) || isEditor;

    const AnimationComponent = getComponentFromName(AnimationComponents, animation, null);

    return (
        <Widget
            withoutUI
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {AnimationComponent !== null ? <AnimationComponent /> : null}

                {!withoutImage && AnimationComponent === null ? (
                    <div className={styles.imageContainer}>
                        <Image
                            className={styles.image}
                            pictureClassName={styles.picture}
                            media={image}
                            size="responsive"
                            withoutSize
                            withPlaceholder
                        />
                    </div>
                ) : null}

                <div
                    className={classNames([
                        styles.content,
                        {
                            [styles.withBefore]: withBefore,
                            [styles.withAfter]: withAfter,
                        },
                    ])}
                >
                    {withBefore || placeholder ? (
                        <div className={styles.before}>
                            {category !== null || isEditor || placeholder ? (
                                <Category
                                    {...category}
                                    placeholder={placeholder}
                                    className={classNames([
                                        styles.category,
                                        {
                                            [styles.placeholder]: placeholder,
                                        },
                                    ])}
                                    withLink={!isEditor}
                                    clickRef="document-header"
                                    // editable="category"
                                    // editablePicker="categories"
                                />
                            ) : null}
                            <Surtitle
                                surtitle={surtitle}
                                placeholder={placeholder}
                                className={styles.surtitle}
                                withLink
                                clickRef="document-header"
                                // editable="surtitle"
                            />
                        </div>
                    ) : null}
                    <DocumentTitle
                        className={styles.title}
                        editable="title"
                        html={title}
                        placeholder={placeholder}
                    />
                    {withAfter || placeholder ? (
                        <div className={styles.after}>
                            {subtitle !== null || isEditor || placeholder ? (
                                <DocumentSubtitle
                                    className={styles.subtitle}
                                    editable="subtitle"
                                    placeholder={placeholder}
                                >
                                    {subtitle}
                                </DocumentSubtitle>
                            ) : null}

                            {hasAuthor || placeholder ? (
                                <Author
                                    {...author}
                                    clickRef="document-header"
                                    prefix="Par"
                                    placeholder={placeholder}
                                    className={styles.author}
                                    nameClassName={styles.authorName}
                                    initialsClassName={styles.authorInitials}
                                    withRole
                                    withLink
                                />
                            ) : null}

                            {date !== null || isEditor || placeholder ? (
                                <DocumentMetadata
                                    className={classNames([
                                        styles.date,
                                        {
                                            [styles.hasAuthor]: hasAuthor,
                                        },
                                    ])}
                                    placeholder={placeholder}
                                >
                                    <Date date={date} />
                                </DocumentMetadata>
                            ) : null}

                            {sponsor !== null || isEditor ? (
                                <Sponsor {...sponsor} withImage className={styles.sponsor} />
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </Widget>
    );
}

DocumentDefaultHeader.propTypes = propTypes;
DocumentDefaultHeader.defaultProps = defaultProps;

export default DocumentDefaultHeader;
