/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearch } from 'wouter';

import { useAuthProviderLogin } from '../../hooks/useAuth';
import * as AppPropTypes from '../../lib/PropTypes';

import { useApi } from '../../contexts/ApiContext';
import { useIsCheckingAuth } from '../../contexts/AuthContext';
import { useFacebookSdk, useFacebookToken } from '../../contexts/FacebookContext';
import { useGoogleSdk, useGoogleToken } from '../../contexts/GoogleContext';
import { useRequestBase } from '../../contexts/RequestContext';
import LoadingIcon from '../icons/LoadingIcon';
import FacebookIcon from '../icons/socials/FacebookIcon';
import GoogleIcon from '../icons/socials/GoogleIcon';
import RoundedButton from './RoundedButton';

import styles from '../../styles/buttons/sso-button.module.css';

const providers = [
    {
        id: 'facebook',
        label: 'Facebook',
        icon: FacebookIcon,
    },
    {
        id: 'google',
        label: 'Google',
        icon: GoogleIcon,
    },
];

const propTypes = {
    provider: PropTypes.string.isRequired,
    nextUrl: PropTypes.string,
    subscription: PropTypes.string,
    source: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    theme: PropTypes.oneOf(['dark', 'light']),
    iconOnly: PropTypes.bool,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
    onComplete: PropTypes.func,
    onClick: PropTypes.func,
};

const defaultProps = {
    nextUrl: null,
    source: null,
    subscription: null,
    size: 'medium',
    theme: 'dark',
    iconOnly: false,
    className: null,
    buttonRef: null,
    onComplete: null,
    onClick: null,
};

function SsoButton({
    provider,
    nextUrl,
    source,
    subscription,
    size,
    theme,
    iconOnly,
    className,
    buttonRef,
    onComplete,
    onClick,
    ...props
}) {
    const api = useApi();
    const { loginAsync, isPending: isLoading = false } = useAuthProviderLogin(provider);
    const baseUri = useRequestBase();
    const [location] = useLocation();
    const locationSearch = useSearch();
    const isCheckingAuth = useIsCheckingAuth();
    const absoluteNextUrl = `${baseUri}${location}?${locationSearch}`;
    const { baseUrl: authBaseUrl = null } = api.auth;
    const { label, icon: Icon } = providers.find(({ id }) => id === provider) || {};
    const finalNextUrl = nextUrl || absoluteNextUrl;
    const query = useMemo(
        () =>
            queryString.stringify({
                next: finalNextUrl,
                source,
                subscription,
            }),
        [finalNextUrl, source, subscription],
    );

    const facebookSdk = useFacebookSdk();
    const facebookToken = useFacebookToken();
    const googleSdk = useGoogleSdk();
    const googleToken = useGoogleToken();
    const ref = useRef();
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        if (provider === 'facebook' && facebookSdk !== null) {
            facebookSdk.XFBML.parse(ref.current);
        } else if (provider === 'google' && googleSdk !== null) {
            googleSdk.id.renderButton(
                ref.current,
                {
                    theme: theme === 'dark' ? 'filled_black' : 'outline',
                    size,
                    type: iconOnly ? 'icon' : 'standard',
                    text: 'signin',
                    click_listener: () => {
                        setClicked(true);
                    },
                }, // customization attributes
            );
        }
    }, [provider, facebookSdk, googleSdk, size, iconOnly, theme]);

    const loadingRef = useRef(false);
    useEffect(() => {
        let token = null;
        if (provider === 'facebook') {
            token = facebookToken;
        } else if (provider === 'google') {
            token = googleToken;
        }

        if (isCheckingAuth || token === null || !clicked || loadingRef.current) {
            return () => {};
        }

        loadingRef.current = true;
        let canceled = false;
        loginAsync({
            token,
            source,
            subscription,
        }).then((newUser) => {
            loadingRef.current = false;
            setClicked(false);
            if (onComplete !== null && !canceled) {
                onComplete(newUser);
            }
        });
        return () => {
            canceled = true;
        };
    }, [
        provider,
        facebookToken,
        googleToken,
        source,
        subscription,
        isCheckingAuth,
        onComplete,
        clicked,
    ]);

    const onClickFacebook = useCallback(
        (e) => {
            setClicked(true);
            facebookSdk.login(() => {}, { scope: 'public_profile,email' });
            if (onClick !== null) {
                onClick(e);
            }
        },
        [facebookSdk, onClick],
    );

    if (provider === 'google') {
        return (
            <div
                className={classNames([
                    styles.external,
                    styles[provider],
                    styles[size],
                    {
                        [styles.iconOnly]: iconOnly,
                    },
                    className,
                ])}
            >
                <div ref={ref} />
                {isLoading ? (
                    <div className={styles.loading}>
                        <LoadingIcon className={styles.icon} />
                    </div>
                ) : null}
            </div>
        );
    }

    if (provider === 'facebook') {
        return (
            <RoundedButton
                ref={buttonRef}
                className={classNames([
                    styles.container,
                    styles[size],
                    styles[theme],
                    {
                        [styles.iconOnly]: iconOnly,
                    },
                    className,
                ])}
                onClick={onClickFacebook}
                {...props}
            >
                <span className={styles.iconContainer}>
                    {isLoading ? (
                        <LoadingIcon className={classNames([styles.icon, styles.loading])} />
                    ) : (
                        <Icon className={styles.icon} />
                    )}
                </span>
                <span className={styles.label}>Se connecter</span>
            </RoundedButton>
        );
    }

    return (
        <RoundedButton
            href={`${authBaseUrl}/${provider}?${query}`}
            external
            target="_self"
            ref={buttonRef}
            className={classNames([styles.container, className])}
            onClick={onClick}
            {...props}
        >
            <span className={styles.iconContainer}>
                <Icon className={styles.icon} />
            </span>
            <span className={styles.label}>{label}</span>
        </RoundedButton>
    );
}

SsoButton.propTypes = propTypes;
SsoButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <SsoButton {...props} buttonRef={ref} />);
